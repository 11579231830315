.notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    overflow-y: auto;
}
.notification-icon{ 
    display: flex;
    justify-content: flex-end; /* Moves the button to the right */
    align-items: center;
    width: 100%; /* Ensures it stretches across the screen */
    .notification-button {

        width: 200px; 
        font-size: 14px; 
        font-weight: 600;
        height: 24px;
        border-radius: 5px;
        margin: 2px;
        border:#1976d2 1px solid;
        color:#1976d2;
    }
}


.minimize-button {
    background: none;
    border: none;
    color: #1976d2;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 4px 8px;
    margin-left: auto;
    font-weight: 500;

    &:hover {
        color: white;
        background-color: #1976d2;
        border-radius: 8px;
    }
}

.notification-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    max-width: 8px;
    max-height: 8px;
    min-width: 8px;
    min-height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-left: 8px;
}

.notification-container {
    display: flex;
    flex-direction: column;
    padding: 24px; 
    background-color: #fff;
    width: 500px; 
    border-radius: 8px;
    max-height: 500px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    overflow-y: auto; 

    
    .notification-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        
        p{
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.87);
            margin: 8px 0;
            font-weight: 700;
            padding-left: 8px;
            
        }
    }

}

.notification-empty {
    font-size: 1rem;
    color: rgb(105, 105, 105);
    text-align: left;
    margin-top: 5px;
    margin-bottom: 15px;
    align-items: center;

}


.notification-full {
    overflow-y: auto;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.6;
    padding: 8px 0;
    .instructor-feedback{
        display: block; /* Ensures padding applies to all lines */
        padding-left: 10px;
        white-space: pre-wrap;
    }


}

.rating-section {
    margin-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 16px;

    .rating-question{
        padding-left: 10px;
        .thumb-buttons {
            display: flex;
            gap: 8px;
            margin: 12px 0;
            
        }
    }

    

    .submit-button,
    .close-button {
        background: none;
        border: none;
        color: #1976d2;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        padding: 8px 16px; 
        transition: all 0.3s ease;
        border-radius: 10px;
        margin-top: 10px;

        &:hover {
            color: white;
            background-color: #1976d2;
        }
    }

    .close-button {
        
        &:hover {

            background-color: #333;
        }
    }

    
}
.rating-message {
    font-size: 0.85rem;
    color: #4caf50; 
    margin-top: 8px;
}
.button-container{ 

    display: flex;
    justify-content: flex-end;
    .submit-button {
        margin-right: 1em;

        background: none;
        border: none;
        color: #1976d2;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        padding: 8px 16px;
        transition: all 0.3s ease;
        border-radius: 10px;
        margin-top: 10px;

        &:hover {
            color: #0c457d;
            transform: translateY(-2px);
            background-color: #fff;
        }
    }
}
.close-button {
    background: none;
    border: none;
    color: #d21919;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s ease;
    border-radius: 50%;
    

    &:hover {
        color: #6e0b0b;
        transform: translateY(-2px);
    }
}